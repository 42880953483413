import { Controller } from "@hotwired/stimulus"
import ab001ProminentBankingOverview from "../experiments/ab001-prominent-banking-overview";

export default class extends Controller {
  connect() {
    this.initExperiments(window.gb);
  }

  initExperiments(gb) {
    if (!gb) return;

    ab001ProminentBankingOverview(gb);
  }
}
