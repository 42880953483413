import { Controller } from "@hotwired/stimulus"
import Cookies from 'js-cookie'
import UAParser from 'ua-parser-js'

export default class extends Controller {
  static targets = [ "browser", "device", "referrerMessage", "explanation", "referrer", "referrerUrl", "personalIdentifier", "websiteVisitorId", "campaignId", "adgroupId", "adId", "keyword", "pageName", "pageUri", "landingPageName", "landingPageUrl", "utmMedium", "utmCampaign", "utmSource", "name", "email"];

  connect() {
    this._setOriginData()
    this._showReferrerMessage()
    this._showFormErrors()
    this._fillFormFromParameters()

    document.addEventListener('setCookiesCompleted', () => {
      this._setOriginData()
      this._showReferrerMessage()
    });
  }

  _fillFormFromParameters() {
    const urlParams = new URLSearchParams(window.location.search);
    let name = urlParams.get("name")
    let email = urlParams.get("email")

    if (name) {
      // We need to trigger focus to preload the recaptcha script
      this.nameTarget.focus()
      this.nameTarget.setAttribute('value', name)
      this.nameTarget.setAttribute('readonly', true)
      this.nameTarget.classList.add("bg-n100")
    }

    if (email) {
      this.emailTarget.setAttribute('value', email)
      this.emailTarget.setAttribute('readonly', true)
      this.emailTarget.classList.add("bg-n100")
    }
  }

  _setOriginData() {
    if (Cookies.get("referrer")) this.referrerTarget.value = Cookies.get("referrer")
    if (Cookies.get("referrer_url")) this.referrerUrlTarget.value = Cookies.get("referrer_url")
    if (Cookies.get("personal_identifier")) this.personalIdentifierTarget.value = Cookies.get("personal_identifier")
    if (Cookies.get("_ga")) this.websiteVisitorIdTarget.value = Cookies.get("_ga")
    if (Cookies.get("campaign_id")) this.campaignIdTarget.value = Cookies.get("campaign_id")
    if (Cookies.get("adgroup_id")) this.adgroupIdTarget.value = Cookies.get("adgroup_id")
    if (Cookies.get("ad_id")) this.adIdTarget.value = Cookies.get("ad_id")
    if (Cookies.get("utm_medium")) this.utmMediumTarget.value = Cookies.get("utm_medium")
    if (Cookies.get("utm_campaign")) this.utmCampaignTarget.value = Cookies.get("utm_campaign")
    if (Cookies.get("utm_source")) this.utmSourceTarget.value = Cookies.get("utm_source")
    if (Cookies.get("keyword")) this.keywordTarget.value = Cookies.get("keyword")
    if (Cookies.get("landing_page_name")) this.landingPageNameTarget.value = Cookies.get("landing_page_name")
    if (Cookies.get("landing_page_url")) this.landingPageUrlTarget.value = Cookies.get("landing_page_url")

    // The campaign ID must be a integer, so we clear it if it's not
    if (isNaN(this.campaignIdTarget.value)) this.campaignIdTarget.value = ""

    let deviceInfo = (new UAParser(window.navigator.userAgent)).getResult();
    this.deviceTarget.value = this._getDevice(deviceInfo);
    this.browserTarget.value = deviceInfo.browser.name;
    this.pageNameTarget.value = window.location.pathname.split('/').filter(String).pop() || 'home'
    this.pageUriTarget.value = window.location.href
  }

  _getDevice(deviceInfo) {
    let device = deviceInfo.device.type;
    if (device == undefined) {
      device = "desktop"
    }

    return device;
  }

  _showReferrerMessage() {
    if (Cookies.get("referrer") != undefined) {
      this.referrerMessageTarget.classList.remove("hidden")
    }
  }

  _showFormErrors() {
    const errorsContainer = document.getElementById('recaptcha-form-errors')
    const errors = new URLSearchParams(window.location.search).getAll("errors")
    if (errors.length > 0) {
      errors.forEach((error) => {
        let list_node = document.createElement('li');
        let text_node = document.createTextNode(error);
        list_node.appendChild(text_node);
        errorsContainer.appendChild(list_node);
      });
      errorsContainer.classList.remove('hidden');
    }
  }
}
